import { checkNullish } from './checkNullish';

const key = 'pathNameStorage';

export const addPathToLocalStorage = (prevPath: string) => {
  const storage = getPathStorageFromLocalStorage();

  storage.push(prevPath);
  if (storage.length > 5) {
    storage.shift();
  }

  localStorage.setItem(key, JSON.stringify(storage));
};

export const getPathStorageFromLocalStorage = (): string[] => {
  const storage = checkNullish(localStorage.getItem(key));
  if (storage) {
    return JSON.parse(storage);
  }
  return [];
};
